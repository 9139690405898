import React from "react"
import SEO from "../components/seo"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"

import Image404 from "../images/undraw_not_found.svg"

const NotFoundPage = () => (
  <Layout>
    <StyledContainer>
      <SEO title="Não encontrado: 404" />
      <h1>NÃO ENCONTRADO</h1>
      <div className="img">
        <img src={Image404} alt="404" />
      </div>
      <p>Essa página não existe...</p>
      <Link to="/">Voltar para a página inicial</Link>
    </StyledContainer>
  </Layout>
)

const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    padding-bottom: 40px;
    font-size: 30px;
  }

  div img {
    width: 100%;
    max-width: 400px;
  }

  p {
    padding-top: 30px;
  }

  a {
    margin-top: 20px;
    color: #57b894;
  }
`

export default NotFoundPage
